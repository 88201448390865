import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  message: PropTypes.shape(),
  validationState: PropTypes.oneOf(['initial', 'valid', 'invalid']),
  field: PropTypes.string,
  maxAge: PropTypes.number,
};

const LocalizedFormError = ({
  message,
  validationState = 'initial',
  field,
  maxAge,
}) => {
  const maxNumber =
    field === 'number' ||
    field === 'stairs' ||
    field === 'floor' ||
    field === 'letter'
      ? 10
      : 50;
  return (
    <div className="mt-1 text-xs text-clr-red-error font-medium">
      {validationState === 'invalid' && (
        <FormattedMessage
          id={message.id}
          defaultMessage={message.defaultMessage}
          values={{
            length: 5,
            max: maxNumber,
            maxAge,
            minAge: 18,
          }}
        />
      )}
      {validationState !== 'invalid' && (
        <span className="invisible">no error</span>
      )}
    </div>
  );
};

LocalizedFormError.propTypes = propTypes;

export default LocalizedFormError;
