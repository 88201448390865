import {
  MOBILE_INSURANCE_PRICE_URL,
  HOSPITALIZATION_INSURANCE_PRICE_URL,
  SPORT_INSURANCE_PRICE_URL,
  SUBSCRIBE_URL,
} from './config';

export const queryMobileInsurancePrice = (data) =>
  fetch(MOBILE_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const queryHospitalizationInsurancePrice = (data) =>
  fetch(HOSPITALIZATION_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const querySportInsurancePrice = (data) =>
  fetch(SPORT_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const submitSubscription = (data) =>
  fetch(SUBSCRIBE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
