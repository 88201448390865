import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { labelWidthClass } from '../settings';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const ErrorText = ({ text = '' }) => (
  <div className="flex justify-end gap-5 none">
    <span className={cx('hidden md:block', labelWidthClass)}></span>
    <span
      className={cx('pt-1 text-xs text-clr-red-error font-medium', 'w-full')}
    >
      {text}
    </span>
  </div>
);

ErrorText.propTypes = propTypes;

export default ErrorText;
