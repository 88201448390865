import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  color: PropTypes.oneOf(['black', 'green']),
  size: PropTypes.oneOf(['headerS', 'headerM']),
  transform: PropTypes.oneOf(['none', 'uppercase']),
};

const TitleText = ({
  text,
  align = 'center',
  color = 'black',
  size = 'headerM',
  transform = 'none',
}) => (
  <p
    className={cx('w-full font-black font-ubuntu', {
      'text-left': align === 'left',
      'text-right': align === 'right',
      'text-center': align === 'center',
      'text-clr-black': color === 'black',
      'text-clr-green': color === 'green',
      'text-2xl lg:text-4xl lg:leading-10 leading-[26px]': size === 'headerM',
      'text-xl lg:text-2xl lg:leading-[26px] leading-5': size === 'headerS',
      'text-none': transform === 'none',
      uppercase: transform === 'uppercase',
    })}
  >
    {text}
  </p>
);

TitleText.propTypes = propTypes;

export default TitleText;
