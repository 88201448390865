import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from '../../Button';
import Tooltip from '../../Tooltip';
import Text from '../../Text';
import TitleText from '../../TitleText';

export const propTypes = {
  tooltip: PropTypes.node,
  buttonDisabled: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  hasExcess: PropTypes.bool,
  priceData: PropTypes.shape(),
};

const protectedProps = {
  fixed: PropTypes.bool,
  containerRef: PropTypes.shape(),
};

const OrderSummaryImpl = ({
  tooltip = null,
  hasExcess = false,
  buttonDisabled = false,
  onButtonClick,
  fixed = false,
  containerRef,
  priceData = {},
}) => {
  const nf = useMemo(
    () => new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' })
  );

  return (
    <div
      ref={containerRef}
      className={cx({
        'fixed bottom-0 left-0 right-0 z-10 bg-clr-subtle-gray': fixed,
      })}
    >
      <div className="flex px-2 py-4 justify-between items-center max-w-4xl mx-auto">
        <div>
          <div>
            <Text
              text="Total"
              size="subTitle"
              color="gray"
              className="mr-2 inline-block"
            />
            {tooltip && <Tooltip tooltipDesc={tooltip} />}
          </div>
          <div>
            <TitleText
              size="headerS"
              text={`${nf.format(priceData.totalPremium || 0)} / Mes`}
              color="green"
            />
          </div>
          {hasExcess && (
            <Text
              text={`Franquicia: ${nf.format(priceData.excess || 0)}`}
              size="bodyS"
              color="gray"
            />
          )}
        </div>
        <div className="w-48 md:w-80">
          <div className="md:block hidden">
            <Button
              onClick={onButtonClick}
              disabled={buttonDisabled}
              label="Contrata online"
              className="w-full"
            />
          </div>
          <div className="md:hidden">
            <Button
              onClick={onButtonClick}
              disabled={buttonDisabled}
              label="Contrata online"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OrderSummaryImpl.propTypes = { ...propTypes, ...protectedProps };

export default OrderSummaryImpl;
