import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import LabelDesign from '../../../components/FormV2/Label/Label';
import { messages } from '../../intl';

const propTypes = {
  message: PropTypes.shape().isRequired,
  isOptional: PropTypes.bool,
  htmlFor: PropTypes.string,
  tooltip: PropTypes.string,
};

const LabelLocalized = ({ message, isOptional = false, ...rest }) => {
  const intl = useIntl();
  const labelHtml = useMemo(() => {
    let str = intl.formatMessage(message);

    if (isOptional) {
      str += intl.formatMessage(messages.fieldLabels.isOptional);
    }

    return str;
  }, [message, isOptional]);

  return <LabelDesign {...rest} html={labelHtml} />;
};

LabelLocalized.propTypes = propTypes;

export default LabelLocalized;
