import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as sources from './sources';

const propTypes = {
  page: PropTypes.oneOf(['sport', 'hospitalization', 'mobile']).isRequired,
  btnLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const btnClass =
  'absolute bottom-[8%] right-[3%] rounded-full font-bold font-ubuntu text-center duration-300 bg-clr-green hover:bg-clr-green-dark text-white text-[0.5rem] py-1.5 px-4';

const btnClassSm = 'sm:text-xs sm:py-3 sm:px-8';
const btnClassLg = 'lg:text-base lg:py-4 lg:px-24';

const AnimBanner = ({ page, btnLabel, onClick }) => (
  <div className="relative background-blue-100 p-0 max-w-7xl">
    <img onClick={onClick} alt="Animated Banner" {...sources[page]} />
    <button
      type="button"
      className={cx(btnClass, btnClassSm, btnClassLg)}
      onClick={onClick}
    >
      {btnLabel}
    </button>
  </div>
);

AnimBanner.propTypes = propTypes;

export default AnimBanner;
