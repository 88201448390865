import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import translations from './translations';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  locale: PropTypes.oneOf(['en', 'es']),
};

const L10nProvider = ({ children, locale = 'es' }) => (
  <IntlProvider messages={translations[locale]} locale={locale}>
    {children}
  </IntlProvider>
);

L10nProvider.propTypes = propTypes;

export default L10nProvider;
