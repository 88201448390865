import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  variant: PropTypes.oneOf(['outlined', 'primary']),
  type: PropTypes.oneOf(['button', 'submit']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const commonClasses =
  'rounded-full text-base text-center leading-4 font-bold font-ubuntu h-14 px-8 py-4';

const primaryClasses = 'hover:bg-clr-green-dark bg-clr-green text-white';

const outlinedClasses =
  'text-clr-gray border-clr-gray border-2 bg-clr-subtle-gray hover:bg-clr-gray hover:text-white';

const Button = ({
  type = 'button',
  variant = 'primary',
  label,
  onClick,
  disabled = false,
  className,
}) => (
  <button
    disabled={disabled}
    type={type}
    onClick={onClick}
    className={cx(
      commonClasses,
      {
        [primaryClasses]: variant === 'primary',
        [outlinedClasses]: variant === 'outlined',
        'py-4 px-12': variant === 'outlined',
        'opacity-20': disabled,
      },
      className
    )}
  >
    {label}
  </button>
);

Button.propTypes = propTypes;

export default Button;
