import React from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';

const tagManagerArgsProd = {
  gtmId: 'GTM-WW9XB98',
};

const tagManagerArgsPreprod = {
  gtmId: 'GTM-5R7WGTR',
};

function getGtmId() {
  if (window.location.href.indexOf('contratacion.bnpparibascardif.es') > -1)
    return window.location.href.indexOf(
      'preprod.contratacion.bnpparibascardif.es'
    ) > -1
      ? tagManagerArgsPreprod
      : tagManagerArgsProd;
  return null;
}

const scriptAdobe = () => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'page_loaded',
    page: {
      language: document.getElementsByTagName('html')[0].getAttribute('lang'),
      title: document.title,
      v_url: document.URL,
    },
  });
};

const AdobeScript = () => {
  const gtmId = getGtmId();
  if (gtmId === null) return null;

  TagManager.initialize(gtmId);
  return (
    <Helmet>
      <script>{scriptAdobe()}</script>
    </Helmet>
  );
};

export default AdobeScript;
