/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { fieldCommonClasses } from '../settings';
import ErrorText from '../ErrorText';
import Label from '../Label';

import './selectbox.css';

const propTypes = {
  type: PropTypes.oneOf(['full', 'pref']),
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  showValid: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  specType: PropTypes.string,
  image: PropTypes.element,
};

const SelectBox = ({
  label = '',
  value = '',
  options = [],
  errorText,
  showValid = false,
  disabled = false,
  onChange,
  specType,
  className = '',
  image,
  ...props
}) => (
  <div
    className={cx('select-box', {
      'w-full': specType === undefined,
      'w-[105px]': specType === 'opt-box',
    })}
  >
    <label className={fieldCommonClasses}>
      {label && <Label text={label} />}

      {/* The Select Box itself */}
      <div
        className={cx(
          className,
          'relative flex w-full h-fit after-common',
          {
            'after-arrow': !showValid,
            'after-check after:font-icons after:text-clr-green': showValid,
            'after:opacity-30': disabled,
          },
          'after:right-3.5'
        )}
      >
        {image && (
          <span className="absolute left-2.5 self-center w-[21px]">
            {image}
          </span>
        )}

        <select
          {...props}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className={cx(
            className,
            'text-sm text-clr-black leading-[22px] p-2.5 w-full border-2 rounded focus:ring-transparent appearance-none',
            {
              'opacity-30': disabled,
              'cursor-pointer': !disabled,
              'border-clr-gray-light': !errorText,
              'border-clr-red-error': errorText,
              'focus:border-clr-green hover:border-clr-green':
                !errorText && !disabled,
              'focus:border-clr-red-error hover:border-clr-red-error':
                errorText,
              'pl-10': !!image,
            },
            'transition-colors ease-in-out delay-150'
          )}
        >
          <option value="" label="" className="none hidden"></option>

          {options.map((opt, i) => (
            <option key={i} value={opt.value} label={opt.label || opt.value}>
              {opt.label || opt.value}
            </option>
          ))}
        </select>
      </div>
    </label>

    {specType !== 'opt-box' && errorText && <ErrorText text={errorText} />}
  </div>
);

SelectBox.propTypes = propTypes;

export default SelectBox;
