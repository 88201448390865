import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  phone: PropTypes.string.isRequired,
  label: PropTypes.string,
  phoneLinkRef: PropTypes.shape(),
};

const PhoneLink = ({ phone = '900 533 691', label, phoneLinkRef }) => (
  <a
    ref={phoneLinkRef}
    className="flex text-base font-bold gap-0.5 items-center font-ubuntu"
    href={`tel:${phone}`}
  >
    <span className="text-clr-green font-icons text-2xl"></span>
    &nbsp;
    <span className="text-clr-blue">{label || phone}</span>
  </a>
);

PhoneLink.propTypes = propTypes;

export default PhoneLink;
