import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Tooltip from '../../Tooltip';

const propTypes = {
  html: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  tooltip: PropTypes.string,
};

const Label = ({ htmlFor, html, tooltip }) => (
  <label
    htmlFor={htmlFor}
    className={cx(
      'inline-block text-clr-gray md:mt-3',
      'text-base h-fit leading-4 font-bold max-w-[320px]',
      {
        'leading-5': !!tooltip,
      }
    )}
  >
    <span dangerouslySetInnerHTML={{ __html: html }} />
    {tooltip && (
      <div className="inline-block pl-3 font-normal">
        <Tooltip tooltipDesc={tooltip} />
      </div>
    )}
  </label>
);

Label.propTypes = propTypes;

export default Label;
