import React from 'react';
import PropTypes from 'prop-types';

import * as flags from '../../assets/Images/flags';

const propTypes = {
  country: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Flags = ({ country, ...props }) => (
  <img
    src={flags[country].src}
    alt={flags[country].alt}
    className="w-full"
    {...props}
  />
);

Flags.propTypes = propTypes;

export default Flags;
